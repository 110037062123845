import request from '@/utils/request'

/**
 * 活动图片查询
 */
 export function config () {
  return request({
    url:'app/config',
    method: 'get'
  })
}

/**
 * 抽奖动态
 */
 export function luckList () {
  return request({
    url:'app/luck/list',
    method: 'get'
  })
}

/**
 * 奖品列表
 */
 export function goodsList () {
  return request({
    url:'app/goods/list',
    method: 'get'
  })
}

/**
 * 可用抽奖次数
 */
export function userInfo (data) {
  return request({
    url:'app/user/info',
    method: 'post',
    data
  })
}

/**
 * 抽奖接口
 */
 export function luck (data) {
  return request({
    url:'app/luck',
    method: 'post',
    data
  })
}

/**
 * 我的中奖记录
 */
 export function myLuckList (data) {
  return request({
    url:'app/myLuck/list',
    method: 'post',
    data
  })
}

/**
 * 修改收货人信息
 * @param {Object} data
 */
 export function addrUpdate (data) {
  return request({
    url:'app/addr/update',
    method: 'post',
    data
  })
}